import { inject }           from 'aurelia-framework';
import { BaseRepository }   from 'modules/core/services/base-repository';
import { CustomHttpClient } from 'resources/services/custom-http-client';

@inject(CustomHttpClient)
export class EntitiesRepository extends BaseRepository {
    baseUrl = 'management/concessions/entities';

    /**
     * Returns all teams for the given entity
     *
     * @return {*}
     */
    teams(entityId) {
        return this.httpClient.get(`${this.baseUrl}/${entityId}/teams`);
    }

    /**
     * Returns active user entities
     *
     * @return {*}
     */
    activeUserEntities(criteria = { }) {
        return this.httpClient.post(`${this.baseUrl}/active-user-entities`, criteria);
    }
}
